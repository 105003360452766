@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
  font-family: 'Roboto';
}

.logo_img img {
  width: 70px;
  height: 60px;
}

.header__navbar {
  background: #fff;
  z-index: 100;
}

.header__navbar .navbar-toggler {
  color: #8b8d8f !important;
  border-color: #8b8d8f !important;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.1rem !important;
}

.header__navbar .navbar-toggler-icon {
  background-image: url("../../images/menu.png");
}

.navbar__justify {
  justify-content: end;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1em !important;
  height: 1em !important;
}


.menu3 .nav-link {
  color: #565758 !important;
}

.menu3 {
  list-style: none;
}

.menu3 li span {
  display: block;
  height: 100%;
  position: relative;
  display: flex;
  transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.menu3 li ul {
  padding: 0px 10px;
}

.menu3 li span .drop__icon__i {
  padding: 6px 0px 0px 3px;
}

/* Style 3: Just animate the :after element's width from 0 to 100% */

.menu3 li span:after {
  content: "";
  position: absolute;
  width: 0%;
  height: 1px;
  display: block;
  transition: all 0.3s ease;
  bottom: -8%;

}

.menu3 li span:hover::after {
  width: 100%;
  height: 2px;
  background-color: rgb(12, 7, 7);
}

.menu3 li span .nav-link {
  color: #707677;
}

.menu3 li:hover .dropdown-menu {
  display: block;
}

.menu3 li:hover .tabs__drop {
  left: -125px !important;
}

.active-nav {
  color: #272727 !important;
  bottom: 9px;
}

.navbar-brand {
  font-size: 14px !important;
  font-weight: 600 !important;
}

.navbar-dark .navbar-toggler {
  color: #42a108 !important;
  border-color: #42a108 !important;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("../../images/menu.png");
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1em !important;
  height: 1em !important;
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 0.1rem !important;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.2rem !important;
}

#drop_down .dropdown-menu[data-bs-popper] {
  top: 100%;
  left: -125px !important;
  margin-top: var(--bs-dropdown-spacer);
}

#drop_down .dropdown-menu {
  -bs-dropdown-link-active-bg: #fff !important;
}

#drop_down .dropdown-item:focus,
.dropdown-item:hover {
  background-color: #f8f9fa !important;
}

.menu_item {
  text-align: center !important;
}


@media only screen and (max-width: 768px) {

  /* For desktop: mobile:*/
  .active-nav {
    color: none !important;
    bottom: 9px;
  }

  .menu3 li ul {
    padding: 0px;
  }

  .menu3 li span {
    padding-left: 8px;
  }

  .menu3 li span:hover::after {
    width: 100%;
    height: 2px;
    background-color: rgb(209, 74, 74);
  }

  .menu3 li span:hover::after {
    width: 100%;
    height: 2px;
    background-color: white !important;
  }

  .logo_img img {
    width: 55px;
    height: 45px;
  }

  .navbar__justify {
    justify-content: start;
  }

  .header__navbar {
    padding: 6px;
  }
}

@media only screen and (max-width: 820px) {

  /* For desktop: ipad:*/
  .active-nav {
    color: none !important;
    bottom: 9px;
  }

  .menu3 li span:hover::after {
    width: 100%;
    height: 2px;
    background-color: rgb(209, 74, 74);
  }

  .menu3 li span {
    padding-left: 8px;
  }

  .menu3 li span:hover::after {
    width: 100%;
    height: 2px;
    background-color: white !important;
  }

  .logo_img img {
    width: 55px;
    height: 45px;
  }

}

@media only screen and (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}