/* .tree .co__sub__tree {
    margin-left: 20px;
} */

/* .tree li {
    list-style-type: none;
    margin: 10px;
    position: relative;
}

.tree li::before {
    content: "";
    position: absolute;
    top: -14px;
    left: -42px;
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-radius: 0 0 0 0px;
    width: 43px;
    height: 35px;
}

.tree li::after {
    position: absolute;
    content: "";
    top: 126px;
    left: 0px;
    border-left: 1px solid #ccc;
    border-top: 1px solid #ccc;
    border-radius: 0px 0 0 0;
    width: 34px;
    height: 100%;
}
.tree.co__sub__tree li::before {
    content: "";
    position: absolute;
    top: -14px;
    left: -42px;
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-radius: 0 0 0 0px;
    width: 43px;
    height: 35px;
}
.tree .sub__tree:last-child::after {
    display: none;
}

.tree .sub__tree:last-child:before {
    border-radius: 0 0 0 5px;
}

ul.tree>.sub__tree:first-child::before {
    display: none;
}

ul.tree>.sub__tree:first-child::after {
    border-radius: 5px 0 0 0;
}

.tree .sub__tree img {
    border: 1px #ccc solid;
    border-radius: 5px;
}

.tree li a:hover,
.tree li a:hover+ul li a,
.tree li a:focus,
.tree li a:focus+ul li a {
    background: #ccc;
    color: #000;
    border: 1px solid #000;
}

.tree li a:hover+ul li::after,
.tree li a:focus+ul li::after,
.tree li a:hover+ul li::before,
.tree li a:focus+ul li::before .tree li a:hover+ul::before,
.tree li a:focus+ul::before .tree li a:hover+ul ul::before,
.tree li a:focus+ul ul::before {
    border-color: #000;
} */

.tree .card {
    min-width: 150px;
    max-width: 350px;
    min-height: 100px;
    max-height: 210px;
    overflow: hidden;
    display: inline-block;
}

.tree .card-body {
    padding: 6px 4px !important;
}
.tree .card__img {
    margin-top: 2px;
    text-align: center;
}
.tree .card__img img {
    width: 150px;
    height: 150px;
    text-align: center;
}
.tree .board__title {
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 2px !important;
}

.tree .board__desc {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 2px !important;
}

.tree ul {
    margin-left: 20px;
}

.tree li {
    list-style-type: none;
    margin: 10px;
    position: relative;
}

.tree li::before {
    content: "";
    position: absolute;
    top: -17px;
    left: -20px;
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-radius: 0 0 0 0px;
    width: 20px;
    height: 35px;
}

.tree li::after {
    position: absolute;
    content: "";
    top: 17px;
    left: -20px;
    border-left: 1px solid #ccc;
    border-top: 1px solid #ccc;
    border-radius: 0px 0 0 0;
    width: 20px;
    height: 100%;
}

.tree li:last-child::after {
    display: none;
}

.tree li:last-child:before {
    border-radius: 0 0 0 5px;
}

ul.tree>li:first-child::before {
    display: none;
}

ul.tree>li:first-child::after {
    border-radius: 5px 0 0 0;
}

.tree li img {
    /* border: 1px #ccc solid; */
    border-radius: 5px;
    /* padding:2px 5px; */
}


@media only screen and (max-width: 768px) {
    .tree .card {
        min-width: 150px;
        max-width: 350px;
        min-height: 100px;
        max-height: 235px;
        overflow: hidden;
        display: inline-block;
    }
    .tree ul {
        margin-left: 0px;
    }

    .content ul {
        padding-left: 0.5rem;
    }

    .tree li::before {
        content: "";
        position: absolute;
        top: -22px;
        left: -18px;
        border-left: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        border-radius: 0 0 0 0px;
        width: 19px;
        height: 36px;
    }

    .tree li::after {
        position: absolute;
        content: "";
        top: 13px;
        left: -18px;
        border-left: 1px solid #ccc;
        border-top: 1px solid #ccc;
        border-radius: 0px 0 0 0;
        width: 20px;
        height: 100%;
    }

}