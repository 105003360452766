.btn__bmfc {
    margin-top: 31px;
    text-align: center;
}
.add__btn__bmfc {
    font-size: 17px;
    border: 1px solid #a9abaf;
    border-radius: 4px;
    padding: 3px 15px;
}




@media only screen and (max-width: 768px) {
    .btn__bmfc {
        margin-top: 10px;
        text-align: end;
    }
}