.header__text {
    color: rgba(0,0,0,.5)!important;
} 
.back__btn {
    padding: 0.28rem 0.65rem;
    font-size: 14px;
    line-height: 1.5;
    border-radius: 0.9rem;
    color: #fff;
    font-weight: 500;
    background: #4B49AC;
    text-decoration: none;
}
.back__btn:hover {
    color: #fff;
    text-decoration: none;
    background: #4B49AC;
}
.btn__save {
    background: #042954;
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 7px 18px;
    margin-bottom: 7px;
    font-size: 14px;
}
.btn__save:hover {
    background: #042954;
    text-decoration: none;
}
.btn__cancel {
    background: transparent;
    border: 1px solid #042954;
    border-radius: 8px;
    padding: 6px 9px;
    font-size: 14px;
    text-decoration: none;
}
.btn__cancel:hover {
    border: 1px solid #042954;
    text-decoration: none;
}
.form__ptb {
    height: 2rem !important; 
}
/* .form-control-sm, .form-control, .form-select, .form-select-sm {
    padding: 0.20rem 0.5rem !important;
    font-size: 14px !important;
    line-height: 2 !important;
}
.form-control:focus, .form-select:focus {
    box-shadow: none !important;
} */
