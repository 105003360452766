.input__img img {
    width: 140px;
    height: 130px;
}

.close__icon {
    margin-left: 4px;
    margin-top: -6px;
}

