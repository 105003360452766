@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;500&family=Roboto+Slab:wght@100;200;300;400;700&display=swap');

* {
  font-family: 'Roboto';
}

.bg__body {
  background-color: #ffffff;
}

.btn_learn_more {
  background: #5AAB46;
  border-radius: 10px;
  color: white !important;
}

.btn_learn_more:hover {
  color: white !important;
}

.header_text_normal {
  font-weight: 300;
  color: #7c898b;

}

.header_text_normal_bm {
  font-weight: 300;
  color: #7c898b;
  border-bottom-style: solid;
  border-bottom-color: #143D46;
}

.header_text_bold {
  font-weight: 500;
  color: #143D46;
}

.header_description {
  color: #0D2C49;
  line-height: 173%;
  font-size: 15px;
  text-align: justify;
}

.btn_read {
  color: #fff;
  background: #0C8D2D !important;
  box-shadow: 0px 0px 15px 4px rgba(0, 0, 0, 0.15);
  padding: 7px 25px;
  border-radius: 10px 20px;
}

.btn_read:hover {
  color: #fff;
  background: #0C8D2D !important;
  box-shadow: 0px 0px 15px 4px rgba(0, 0, 0, 0.15);
}

.btn__submit {
  color: #fff;
  background: #0C8D2D !important;
  box-shadow: 0px 0px 15px 4px rgba(0, 0, 0, 0.15);
  padding: 7px 25px;
  border-radius: 10px 20px;
  border: 2px solid #0C8D2D;
}

.btn__submit:hover {
  color: #fff;
  background: #0C8D2D !important;
  box-shadow: 0px 0px 15px 4px rgba(0, 0, 0, 0.15);
}

.tour_img img {
  width: 100%;
  height: 320px;
}

/* slider  */
.events_carousel {
  padding: 0px 30px;
}

.events_carousel .slick-prev:before,
.slick-next:before {
  color: #484848 !important;
}

.events_carousel .slick-slider .slick-slide {
  padding: 0 6px;
}

.events_carousel .slick-slider,
.slick-list,
.slick-track {
  height: 100%;
}

.events_carousel_box_card {
  min-height: 100%;
}

.events_carousel_box_card .card {
  min-height: 510px !important;
  max-height: 100px !important;
  overflow: hidden;
  background: #FFFFFF;
  border-radius: 10px;
  border: none !important;
  margin-bottom: 20px;
  box-shadow: 0px 0px 11px 1px rgba(0, 0, 0, 0.15);
  z-index: 1;
}

.events_carousel_date {
  font-size: 14px;
  color: #143D46;
  margin-bottom: 10px !important;
}

.events_carousel_card_title {
  color: #5AAB46;
  line-height: 120%;
  font-weight: 500;
  font-size: 16px;
  text-align: start;
  margin-bottom: 0rem !important;
  min-height: 30px !important;
  max-height: 40px !important;
  overflow: hidden;
}

.events_carousel_discription {
  color: #143D46;
  margin-top: 5px;
  margin-bottom: 14px;
  font-size: 14px;
  line-height: 173%;
  min-height: 150px !important;
  max-height: 130px !important;
  overflow: hidden;
}

.btn_read_carousel {
  color: #fff;
  background: #0C8D2D;
  box-shadow: 0px 0px 15px 4px rgba(0, 0, 0, 0.15);
  padding: 6px 20px;
  border-radius: 10px;
}

.btn_read_carousel:hover {
  color: #fff;
  background: #0C8D2D;
  box-shadow: 0px 0px 15px 4px rgba(0, 0, 0, 0.15);
}

/* our events  */
.our_events_card {
  min-height: 100%;
}

.our_events_card .card {
  min-height: 525px !important;
  max-height: 100px !important;
  overflow: hidden;
  background: #FFFFFF;
  border-radius: 10px;
  border: none !important;
  margin-bottom: 20px;
  box-shadow: 0px 0px 11px 1px rgba(0, 0, 0, 0.15);
  z-index: 1;
}

.upcoming_events_card {
  min-height: 430px !important;
  max-height: 100px !important;
  overflow: hidden;
  background: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 0px 11px 1px rgba(0, 0, 0, 0.15);
  z-index: 1;
}

/* upcoming slider  */
.card_bg {
  min-height: 430px !important;
  max-height: 100px !important;
  overflow: hidden;
  background: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 0px 11px 1px rgba(0, 0, 0, 0.15);
  z-index: 1;
}

.header_event_card_date {
  font-weight: 500;
  line-height: 120%;
  text-align: center;
  color: #143D46;
}

.header_event_card_month {
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: #143D46;
  margin-bottom: 0rem !important;
}

.upc_events_carousel_card_title {
  color: #5AAB46;
  line-height: 120%;
  font-weight: 500;
  font-size: 16px;
  text-align: start;
  margin-bottom: 0rem !important;
  min-height: 30px !important;
  max-height: 40px !important;
  overflow: hidden;
}

.upc_events_carousel_discription {
  color: #143D46;
  margin-top: 5px;
  margin-bottom: 14px;
  font-size: 14px;
  line-height: 173%;
  min-height: 145px !important;
  max-height: 130px !important;
  overflow: hidden;
}



/* tourism news slider */
.tourism_carousel {
  padding: 0px 30px;
}

.tourism_carousel .slick-prev:before,
.slick-next:before {
  color: #484848 !important;
}

.tourism_carousel .slick-slider .slick-slide {
  padding: 0 6px;
}

.tourism_carousel .slick-slider,
.slick-list,
.slick-track {
  height: 100%;
}

.tourism_carousel_box_card {
  min-height: 100%;
}

.tourism_carousel_box_card .card {
  min-height: 450px !important;
  max-height: 100px !important;
  overflow: hidden;
  background: #FFFFFF;
  border-radius: 10px;
  border: none !important;
  margin-bottom: 20px;
  box-shadow: 0px 0px 11px 1px rgba(0, 0, 0, 0.15);
  z-index: 1;
}

.tourism_carousel_date {
  font-size: 14px;
  color: #143D46;
  margin-bottom: 10px !important;
}

.tourism_carousel_card_title {
  color: #5AAB46;
  font-weight: 500;
  line-height: 120%;
  font-weight: 500;
  font-size: 16px;
  text-align: start;
  margin-bottom: 0rem !important;
  min-height: 30px !important;
  max-height: 40px !important;
  overflow: hidden;
}

.tourism_carousel_discription {
  color: #143D46;
  margin-top: 5px;
  margin-bottom: 14px;
  font-size: 14px;
  line-height: 173%;
  text-align: justify;
  min-height: 150px !important;
  max-height: 130px !important;
  overflow: hidden;
}

.gallery_img img {
  width: 100%;
  height: 100%;
}

.partners_carousel {
  padding: 0px 30px;
}

.partners_carousel_box_card .card {
  min-height: 120px !important;
  text-align: start;
  max-height: 100px !important;
  box-shadow: 0px 0px 11px 1px rgb(0 0 0 / 15%);
  border-radius: 20px;
  border: none !important;
  margin: 10px 0px;
  z-index: 1;
}

.partners_carousel .slick-prev:before,
.slick-next:before {
  color: #484848 !important;
}

.partners_carousel .slick-slider .slick-slide {
  padding: 0 6px;
}

.partners_carousel .slick-slider,
.slick-list,
.slick-track {
  height: 100%;
}

/* contact  */

.banner_img img {
  height: 430px;
  width: 100%;
}

.banner_img {
  position: relative;
  text-align: center;
  color: white;
}

.banner_img_text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.contact_bg {
  background: #055C78;
  height: 100%;
  padding: 25px;
  border-radius: 10px;
}

.header_contact {
  color: #fff;
  text-align: center;
  font-weight: 300;
}

.contact_bg .card {
  background: #055C78;
  border: none !important;
}

.contact_bg ul li span {
  color: #fff;
  padding: 0px 30px 0px 70px;
  line-height: 180%;
}

.contact_bg ul li span p {
  padding: 0px 20px;
}

.contact_enquiry {
  padding-left: 40px;
}

/* board of directors  */
.board_hr {
  width: 500px;
  padding: 2px;
  margin: 0 auto;
}

.board_head_card {
  margin: 0 auto;
  width: 270px;
  height: 260px;
  text-align: center;
}

.board_img img {
  width: 180px;
  height: 200px;
}

.board_title {
  margin-top: 15px;
  color: #404040;
  line-height: 120%;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  margin-bottom: 0rem !important;
  overflow: hidden;
}

.board_subtitle {
  color: #767676;
  margin-top: 5px;
  font-size: 14px;
  line-height: 122%;
  min-height: 35px;
  max-height: 15px;
  overflow: hidden;
}

.sub_board_card .card {
  text-align: center;
  border: none !important;
}

.sub_board_img img {
  width: 115px;
  height: 125px;
}

.sub_board_title {
  margin-top: 15px;
  color: #404040;
  line-height: 120%;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  margin-bottom: 0rem !important;
  overflow: hidden;
}

.sub_board_subtitle {
  color: #767676;
  margin-top: 5px;
  font-size: 13px;
  line-height: 122%;
  min-height: 35px;
  max-height: 15px;
  overflow: hidden;
}



.our__team__card .card {
  min-height: 265px;
  max-height: 285px;
  /* background-color: #FBFFF8; */
  background-color: #ffffff;
  border: 0px solid #fff !important;
}

.our__team__card__img img {
  height: 160px;
  width: 160px;
  align-items: center;
  border-radius: 50%;
  /* border: 1px solid #4D9621; */
  /* padding: 12px; */
}

.our__c__card__img img {
  height: 220px;
  width: 100%;
  align-items: center;
  /* border-radius: 50%; */
  /* border: 1px solid #4D9621; */
  /* padding: 12px; */
}
.c__card__title {
  color: #000000;
  font-weight: 600;
  line-height: 120%;
  font-size: 16px;
  text-align: start;
  margin-bottom: 0rem !important;
}
.c__card__subtitle {
  color: #0d2c49c4;;
  font-weight: 400;
  line-height: 120%;
  font-size: 14px;
  margin-top: 8px;
  text-align: start;
  margin-bottom: 0rem !important;
}
.c__card__disc {
  color: #0d2c49c4;
  font-size: 14px;
  line-height: 150%;
  margin-bottom: 6px !important;
}
.cmodal__card__disc {
  color: #0d2c49c4;
  margin-top: 5px;
  margin-bottom: 14px;
  font-size: 14px;
  line-height: 150%;
  text-align: justify;
  overflow: hidden;
  overflow-y: auto;
  min-height: 90px;
  max-height: 130px;
  /* padding: 12px; */
}
.our__team__card__title {
  min-height: 30px;
  max-height: 65px;
  overflow: hidden;
  color: #000000;
  font-weight: 600;
  line-height: 120%;
  font-size: 16px;
  text-align: center;
  overflow: hidden;
  margin-bottom: 0rem !important;
}

.our__team__card__subtitle {
  color: #000000;
  font-weight: 400;
  line-height: 120%;
  font-size: 14px;
  margin-top: 8px;
  text-align: center;
  overflow: hidden;
  margin-bottom: 0rem !important;
}

.our__team__card__discription {
  color: #0d2c49c4;
  margin-top: 5px;
  margin-bottom: 14px;
  font-size: 14px;
  line-height: 120%;
  min-height: 35px !important;
  max-height: 45px !important;
  overflow: hidden;
}

.advisor__card__title {
  color: #000000;
  font-weight: 600;
  line-height: 120%;
  font-size: 16px;
  text-align: center;
  overflow: hidden;
  margin-bottom: 0rem !important;
}
.advisor__card__subtitle {
  color: #000000;
  font-weight: 400;
  line-height: 120%;
  font-size: 14px;
  margin-top: 8px;
  text-align: center;
  overflow: hidden;
  margin-bottom: 0rem !important;
}
.advisory__card__disc {
  color: #0d2c49c4;
  margin-top: 5px;
  margin-bottom: 14px;
  font-size: 14px;
  line-height: 150%;
  text-align: justify;
  overflow: hidden;
  overflow-y: auto;
  min-height: 140px;
  max-height: 150px;
  /* padding: 12px; */
}
.advisor__card__discription {
  color: #0d2c49c4;
  font-size: 15px;
  line-height: 140%;
  margin-bottom: 0.3rem!important;
}
/* react modal consultaion start */
.mymodal {
  width: 90%;
}

.modal__body {
  padding: 0.4rem !important;
}

.c__card__mymodal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 4px;
  width: 65%;
  min-height: 460px;
  max-height: 485px;
}

.c__card__myoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1050;
}

.close_btn {
  background: #c9cecb;
  padding: 8px 11px;
  border-radius: 50%;
}

.close_btn:hover {
  background: #c9edde;
}

.modal__body .card-body {
  flex: 1 1 auto;
  padding: 0.3rem 0.5rem !important;
}

.modal__body hr {
  margin: 0.3rem 0 !important;
}

.h__modal__text {
  color: rgba(0, 0, 0, 0.75);
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0px !important;
}

.modal__close__btn {
  margin-bottom: 0px !important;
}

/* scroll style  for alergy and reaction  */
.g-doc-scroll::-webkit-scrollbar {
  width: 5px;
}

.g-doc-scroll::-webkit-scrollbar-track {
  background: #fff !important;
  border-left: 1px solid #ddd;
}

.g-doc-scroll::-webkit-scrollbar-thumb {
  background: rgb(0 0 0 / 20%) !important;
  border-radius: 10px !important;
  padding: 2px;
}

/* scroll style   *
/* airlines */
/* room booking start  */
.rb_search {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0rem !important;
  width: 100%;
}

.rb_search .MuiOutlinedInput-adornedStart {
  padding-left: 8px !important;
  padding-bottom: 1px !important;
}

.rb_search .MuiIconButton-root {
  padding: 0px !important;
  font-size: 12px !important;
}

.rb_search .MuiOutlinedInput-input {
  padding: 4px 8px !important;
}

.rb_search .MuiSvgIcon-root {
  font-size: 1.3rem !important;
}

/* room booking end */

.airlines_card .card {
  min-height: 300px !important;
  max-height: 100px !important;
  overflow: hidden;
  background: #FFFFFF;
  border-radius: 10px;
  border: none !important;
  margin-bottom: 20px;
  box-shadow: 0px 0px 11px 1px rgba(0, 0, 0, 0.15);
  z-index: 1;
}

.airlines_title {
  color: #0C8D2D;
  line-height: 120%;
  font-weight: 600;
  font-size: 16px;
  text-align: start;
  margin-bottom: 0rem !important;
  min-height: 30px !important;
  max-height: 40px !important;
  overflow: hidden;
}

.airlines_add ul li span {
  font-size: 14px;
  color: #143D46;
}

.airlines_add ul li span p {
  font-size: 14px;
  color: #143D46;
  padding: 0px 15px;
}

.allroom_pagination .link_no {
  border: 0px solid #dee2e6 !important;
  color: #143D46;
}


/* pagination  */
.paginationBttns {
  width: 100%;
  height: 40px;
  list-style: none !important;
  display: flex;
  justify-content: center;
}

.paginationBttns a {
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #dee2e6;
  color: #143D46;
  cursor: pointer;
}

.paginationBttns a:hover {
  color: white;
  background-color: #adb5bd;
}

.paginationActive a {
  color: white;
  background-color: #adb5bd;
}

.paginationDisabled a {
  color: grey;
  background-color: grey;
}

.img__area {
  position: relative;
  width: 100%;
}

.img__size {
  display: block;
  width: 100%;
  height: 230px;
}

.img__overlay {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  height: 100%;
  transition: .5s ease;
  opacity: 0;
  align-items: center;
  color: white;
  font-size: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.img__area:hover .img__overlay {
  opacity: 1;
}


/* scroll bar start  */
.scroll__barGallery__div {
  /* height: 27vh; */
  min-width: 600px;
  max-width: 600px;
  max-height: 90px;
  overflow: hidden;
  overflow-y: scroll;
}
.scroll__bar__div {
  /* height: 27vh; */
  
  max-width: 70px;
  max-height: 90px;
  overflow: hidden;
  overflow-y: scroll;
}

.g-doc-scroll::-webkit-scrollbar {
  width: 5px;
}

.g-doc-scroll::-webkit-scrollbar-track {
  background: #fff !important;
  border-left: 1px solid #ddd;
}

.g-doc-scroll::-webkit-scrollbar-thumb {
  background: rgb(0 0 0 / 20%) !important;
  border-radius: 10px !important;
  padding: 2px;
}

/* scroll bar end  */


/* react modal board director start */
.mymodal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 4px;
  width: 60%;
  min-height: 300px;
  max-height: 535px;
}

.myoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1050;
}

.close_btn {
  background: #c9cecb;
  padding: 5px 8px;
  border-radius: 50%;
}

.close_btn:hover {
  background: #c9edde;
}

.modal__img img {
  width: 100%;
  height: 300px;
}


/* react modal board director start */

.margin__top {
  margin-top: 30px;
}

.embassies__img {
  text-align: center;
}

.embassies__img img {
  width: 60%;
  height: 100px;
}

.embassies__card .card {
  min-height: 350px;
  max-height: auto;
  overflow: hidden;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px 15px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  border: none;
  z-index: 1;
}

.embassies__title {
  color: #101111;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
}

.embassies__desc {
  color: #0D2C49;
  line-height: 130%;
  font-size: 14px;
  text-align: start;
}

.embassies__card p {
  margin-bottom: 4px !important;
}

.news__img img {
  width: 100%;
  height: 320px;
}
.member__list__table{
  width: 100%;
}
.member__list__table thead tr th {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  background: #1d7008;
  color: #fff;
}

.member__list__table tbody tr td {
  text-align: center;
  font-size: 15px;
}

.member__list__table thead tr .th__sr {
  width: 80px;
  text-align: center;
}

.tr__body td p {
  margin-bottom: 0px !important;
  font-size: 15px;
  font-weight: 500;
}

.tr__body td .mini__title {
  font-size: 15px;
  font-weight: 500;
  color: #225ca1;
}

/* tourism start  */
.tourist__nav .nav__bg {
  background: #75b2fa !important;
}

.tourist__nav ul li {
  padding: 0px 0px 0px 0px;
}

.tourist__nav .nav-pills .nav-link.active,
.tourist__nav .nav-pills .show>.nav-link {
  color: #fff !important;
  background-color: #2b6b04 !important;
}
.tourist__nav .nav-pills .nav-item {
  margin-right: 0rem !important;
}
.tourist__nav .nav-pills {
  padding-bottom: 0rem !important;
}

.tourist__link {
  display: block;
  padding: 0.4rem 1rem;
  font-size: 15px;
  color: #565758 !important;
  /* background: #5AAB46 !important; */
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.tourist__table thead tr th {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  background: #1d7008;
  color: #fff;
}

.tourist__table tbody tr td {
  text-align: center;
  font-size: 15px;
}

.tourist__table thead tr .th__sr {
  width: 80px;
  text-align: center;
}

.tourist__table thead tr .th__uadd {
  width: 250px;
  text-align: center;
}

.tourist__table thead tr .th__cn {
  width: 144px;
  text-align: center;
}

.police__card .card {
  min-height: 170px;
  max-height: 260px;
  overflow: hidden;
  background: #FFFFFF;
  box-shadow: 0px 0px 11px 1px rgb(0 0 0 / 15%);
  border-radius: 10px;
  border: none;
  z-index: 1;
}

.police__title {
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  text-align: start;
  padding-left: 7px;
}

.police__desc {
  color: #0D2C49;
  line-height: 130%;
  font-size: 15px;
  text-align: justify;
  margin-bottom: 0.4rem !important;
}
.member__list__card .card {
  min-height: 450px;
  max-height: 450px;
  overflow: hidden;
  background: #FFFFFF;
  box-shadow: 0px 0px 11px 1px rgb(0 0 0 / 15%);
  border-radius: 10px;
  border: none;
  z-index: 1;
}
.member__list__card__img {
  justify-content: center;
  display: flex;
}
.member__list__card__img img {
  height: 160px;
  width: 160px;
  align-items: center;
  border-radius: 50%;
  /* border: 1px solid #4D9621; */
  /* padding: 12px; */
}

.member__list__title {
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  padding-left: 7px;
}
.member__list__subtitle {
  color: #000000;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  padding-left: 7px;
}

.member__list__desc {
  color: #0D2C49;
  line-height: 130%;
  font-size: 15px;
  text-align: justify;
  margin-bottom: 0.4rem !important;
}


.tourism__spot__box_card .card {
  min-height: 400px;
  max-height: 450px;
  overflow: hidden;
  background: #FFFFFF;
  border-radius: 10px;
  border: none !important;
  margin-bottom: 20px;
  box-shadow: 0px 0px 11px 1px rgba(0, 0, 0, 0.15);
  z-index: 1;
}

.tourism__spot__img img {
  width: 100%;
  height: 270px;
}

.tourism__spot__card_title {
  color: #5AAB46;
  font-weight: 500;
  line-height: 120%;
  font-weight: 500;
  font-size: 16px;
  text-align: start;
  margin-bottom: 0rem !important;
  min-height: 30px !important;
  max-height: 40px !important;
  overflow: hidden;
}

.tourism__spot__card__discription {
  color: #143D46;
  font-size: 14px;
  line-height: 160%;
  text-align: justify;
}

.btn__tourism__more {
  margin-top: 30px;
  text-align: center;
}

.btn__tourism__more .btn__more {
  color: #fff;
  background: #0f9e34ed;
  padding: 6px 20px;
}

.btn__tourism__more .btn__more:hover {
  color: #fff;
  background: #0f9e34ed !important;
}

.tourism__spot__modal__subtitle {
  color: #000000;
  font-weight: 400;
  line-height: 120%;
  font-size: 14px;
}

.tourism__spot__modal__discription {
  color: #143D46;
  margin-top: 5px;
  margin-bottom: 14px;
  font-size: 14px;
  line-height: 173%;
  text-align: justify;
  min-height: 130px;
  max-height: 160px;
  overflow: hidden;
}


.committees__card {
  position: relative;
  width: 100%;
  min-height: 316px;
  max-height: 325px;
}

.committees__card__overlay {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  height: 100%;
  transition: .5s ease;
  opacity: 0;
  align-items: center;
  color: white;
  font-size: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.committees__card:hover .committees__card__overlay {
  opacity: 1;
}

.committees__card__img img {
  width: 100%;
  height: 230px;
}

.committees__card__title {
  color: #5AAB46;
  font-weight: 500;
  line-height: 120%;
  font-weight: 500;
  font-size: 16px;
  text-align: start;
  margin-bottom: 0.2rem !important;
}


.committees__img {
  text-align: center;
}

.committees__img img {
  width: 170px;
  height: 170px;
  border-radius: 105px;
}

.committees .committees__modal_title {
  color: #143D46;
  font-weight: 600;
  line-height: 130%;
  font-size: 16px;
  text-align: center;
  margin-bottom: 0.2rem !important;
}

.committees .committees__modal__subtitle {
  color: #143D46;
  font-size: 14px;
  text-align: center;
  margin-bottom: 0.4rem !important;
}

.committees .committees__modal__discription {
  color: #143D46;
  font-size: 14px;
  line-height: 160%;
  text-align: justify;
}



.memberlist__card {
  position: relative;
  width: 100%;
  min-height: 220px;
  max-height: 280px;
}

.memberlist__card__overlay {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  height: 100%;
  transition: .5s ease;
  opacity: 0;
  align-items: center;
  color: white;
  font-size: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.memberlist__card:hover .memberlist__card__overlay {
  opacity: 1;
}
.memberlist__card:hover .memberlist__card__overlay a{
  color: #fff;
}

.memberlist__card__img img {
  width: 100%;
  height: 180px;
}

.memberlist__card__title {
  color: #5AAB46;
  font-weight: 500;
  line-height: 120%;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  margin-bottom: 0.2rem !important;
}


.memberlist__img {
  text-align: center;
}

.memberlist__img img {
  width: 170px;
  height: 170px;
  border-radius: 105px;
}

.memberlist .memberlist__modal_title {
  color: #143D46;
  font-weight: 600;
  line-height: 130%;
  font-size: 16px;
  text-align: center;
  margin-bottom: 0.2rem !important;
}

.memberlist .memberlist__modal__subtitle {
  color: #143D46;
  font-size: 14px;
  text-align: center;
  margin-bottom: 0.4rem !important;
}

.memberlist .memberlist__modal__discription {
  color: #143D46;
  font-size: 14px;
  line-height: 160%;
  text-align: justify;
}

/* tourism end  */
.mm{
  background-image: url('./components/member/CCIFB-Membership-Form.pdf');
}
.membership__card {
  width: 100%;
  min-height: 300px;
  max-height: 320px;
}
/* .membership__card .card{
  overflow: hidden;
  background: #FFFFFF;
  border-radius: 10px;
  border: none !important;
  margin-bottom: 20px;
  box-shadow: 0px 0px 11px 1px rgb(0 0 0 / 15%);
  z-index: 1;
} */

.membership__card__img {
  text-align: center;
}
.membership__card__img img {
  width: 180px;
  height: 180px;
  border-radius: 95px;
}

.membership__card__title {
  color: #5AAB46;
  font-weight: 500;
  line-height: 120%;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  margin-bottom: 0.2rem !important;
}
.membership__card__subtitle {
  color: #143D46;
  font-size: 14px;
  text-align: center;
  margin-bottom: 0.4rem !important;
}


.membership__img {
  text-align: center;
}

.membership__img img {
  width: 170px;
  height: 170px;
  border-radius: 105px;
}
/* scroll style  for alergy and reaction  */
.g-doc-scroll::-webkit-scrollbar {
  width: 8px;
}

.g-doc-scroll::-webkit-scrollbar-track {
  background: #fff !important;
  border-left: 1px solid #ddd;
}

.g-doc-scroll::-webkit-scrollbar-thumb {
  background: rgb(0 0 0 / 20%) !important;
  border-radius: 10px !important;
  padding: 2px;
}

/* scroll style   */

.advisor__card .card {
  min-height: 360px;
  max-height: 430px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0px 0px 11px 1px rgb(0 0 0 / 15%);
  border: none !important;
}

.advisor__card__img img {
  height: 160px;
  width: 160px;
  align-items: center;
  border-radius: 50%;
  /* border: 1px solid #4D9621; */
  /* padding: 12px; */
}

.advisor__card__title {
  color: #000000;
  font-weight: 600;
  line-height: 120%;
  font-size: 16px;
  text-align: center;
  overflow: hidden;
  margin-bottom: 0rem !important;
}

.advisor__card__subtitle {
  color: #000000;
  font-weight: 400;
  line-height: 120%;
  font-size: 15px;
  margin-top: 8px;
  text-align: center;
  overflow: hidden;
  margin-bottom: 0rem !important;
}

/* .advisor__card__discription {
  color: #0d2c49c4;
  font-size: 15px;
  line-height: 140%;
  margin-bottom: 0.3rem !important;
} */

.accom__card .card {
  min-height: 360px;
  max-height: 450px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px 15px rgb(0 0 0 / 6%);
  border: none;
}

.accom__card__img img {
  height: 230px;
  width: 100%;
}

.accom__card__title {
  color: #000000;
  font-weight: 600;
  line-height: 120%;
  font-size: 16px;
  text-align: center;
  overflow: hidden;
  margin-bottom: 0rem !important;
}

.accom__card__subtitle {
  color: #000000;
  font-weight: 400;
  line-height: 120%;
  font-size: 15px;
  margin-top: 8px;
  text-align: center;
  overflow: hidden;
  margin-bottom: 0rem !important;
}

.accom__card__discription {
  color: #0d2c49c4;
  font-size: 15px;
  line-height: 140%;
  margin-bottom: 0.3rem !important;
}

.btn__accom {
  color: #fff;
  background: #0f9e34ed;
  padding: 6px 20px;
}

.accom__modal__img img {
  width: 100%;
  height: 300px;
}

.accom__modal__info p {
  margin-bottom: 0.5rem !important;
}

.btn__accom__more .btn__more {
  color: #fff;
  background: #0f9e34ed;
  padding: 6px 20px;
}

.btn__accom__more .btn__more:hover {
  color: #fff;
  background: #0f9e34ed !important;
}

.accom__slider .react-slideshow-container .default-nav {
  height: 25px;
  width: 25px;
}

.accom__search {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0rem !important;
  width: 100%;
}

.accom__search .MuiOutlinedInput-adornedStart {
  padding-left: 8px !important;
  padding-bottom: 1px !important;
}

.accom__search .MuiIconButton-root {
  padding: 0px !important;
  font-size: 12px !important;
}

.accom__search .MuiOutlinedInput-input {
  padding: 4px 8px !important;
}

.accom__search .MuiSvgIcon-root {
  font-size: 1.3rem !important;
}

.pres__message__img img {
  width: 100%;
  height: 330px;
  padding-right: 40px;
}

.festivals__card .card {
  min-height: 360px;
  max-height: 450px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px 15px rgb(0 0 0 / 6%);
  border: none;
}

.festivals__card__img img {
  height: 230px;
  width: 100%;
}

.festivals__card__title {
  color: #000000;
  font-weight: 600;
  line-height: 120%;
  font-size: 16px;
  text-align: start;
  overflow: hidden;
  margin-bottom: 0rem !important;
}

.festivals__card__subtitle {
  color: #000000;
  font-weight: 400;
  line-height: 120%;
  font-size: 15px;
  margin-top: 8px;
  text-align: start;
  overflow: hidden;
  margin-bottom: 0rem !important;
}

.festivals__card__discription {
  color: #0d2c49c4;
  font-size: 15px;
  line-height: 140%;
  text-align: justify;
  margin-bottom: 0.3rem !important;
}

.festivals__img img {
  width: 90%;
  height: 400px;
}

.about__bang__img img {
  width: 80%;
  height: 400px;
}
.bmf__table {
  width: 75%;
}
.bmf__table thead tr th {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  background: #1d7008;
  color: #fff;
}

.bmf__table tbody tr td {
  text-align: center;
  font-size: 15px;
}

.bmf__table thead tr .th__sr {
  width: 80px;
  text-align: center;
}
.bmf__table thead tr .th__continent {
  width: 300px;
  text-align: center;
}
.bmf__criteria li {
  text-align: start;
  list-style-type: decimal;
}
.bmf__criteria li a{
 padding-left: 4px;
}

.mem__criteria li {
  list-style-type: decimal;
}

.membership__table {
width: 75%;
}
.membership__table thead tr th {
  text-align: Start;
  font-size: 15px;
  font-weight: 500;
  background: #1d7008;
  color: #fff;
}

.membership__table tbody tr td {
  text-align: start;
  font-size: 15px;
}

.tr__body .border__bottom tr {
  border-bottom: 1px solid black;
}

.news__desc {
  color: #0d2c49c4;
  font-size: 15px;
  line-height: 140%;
  text-align: start;
  margin-bottom: 0.6rem !important;
}
.board__desc {
  color: #0d2c49c4;
  font-size: 15px;
  line-height: 140%;
  text-align: start;
  margin-bottom: 0.6rem !important;
}

/* scroll bar start  */

.setup-card {
  /* margin-bottom: 15px; */
  padding: 0px 2px 0px 2px;
  /* box-sizing: border-box; */
  /* vertical-align: top; */
  /* background-color: #fff; */
  /* margin-right: 15px; */
  /* border-radius: 14px; */
  /* border: 1px solid #D9E0EB; */
  /* min-height: 200px; */
  /* max-height: 225px; */
  overflow: hidden;
  /* overflow-y: scroll; */
}

.setup-card .scroll-sidebar {
  height: 20vh;
  /* overflow: hidden; */
  padding: 0px 5px 0px 5px;
  overflow-y: scroll;
}
/* scroll bar end  */













@media only screen and (max-width: 768px) {
  .member__list__table{
    width: 100%;
  }
  .member__list__table tbody tr td {
    font-size: 14px;
  }
  
  .member__list__table thead tr .th__sr {
    width: 70px;
  }

  .bmf__table {
    width: 100%;
  }
  .bmf__table thead tr .th__sr {
    width: 70px;
  }
  .bmf__table thead tr .th__continent {
    width: 40%;
  }
  .bmf__table tbody tr .td__country {
    font-size: 14px;
    padding-left: 14px;
  }
  .membership__table tbody tr td,  .membership__table tbody tr td  a {
    font-size: 14px;
  }
  /* react modal board director start */
  .mymodal {
    width: 90%;
  }

  .modal__body {
    padding: 0.4rem !important;
  }

  .mymodal {
    transform: translate(-50%, -80%);
  }

  /* react modal board director start */

  /* For desktop: mobile:*/
  .tour_img img {
    width: 100%;
    height: 210px;
    padding: 0px 10px;
  }

  h3 {
    font-size: 20px !important;
  }

  .tourism_carousel_date {
    font-size: 13px;
  }

  .partners_carousel .slick-dots {
    bottom: -35px !important;
  }

  .btn_read {
    padding: 5px 12px;
    border-radius: 10px 20px;
  }

  .events_carousel_box_card .card {
    min-height: 425px !important;
    max-height: 100px !important;
  }

  .tourism_carousel_box_card .card {
    min-height: 400px !important;
    max-height: 100px !important;
  }

  .events_carousel_card_title {
    font-size: 15px;
    margin-bottom: 0rem !important;
    min-height: 25px !important;
    max-height: 35px !important;
    overflow: hidden;
  }

  .events_carousel_discription {
    font-size: 13px;
    line-height: 163%;
    min-height: 125px !important;
    max-height: 110px !important;
    overflow: hidden;
  }

  .our_events_card .card {
    min-height: 490px !important;
    max-height: 100px !important;
  }

  .card_bg {
    min-height: 380px !important;
    max-height: 100px !important;
  }

  .tourism_carousel_discription {
    font-size: 13px;
    line-height: 163%;
    min-height: 145px !important;
    max-height: 130px !important;
    overflow: hidden;
  }

  .partners_carousel_box_card .card {
    min-height: 120px !important;
    max-height: 100px !important;
  }

  .banner_img img {
    height: 230px;
    width: 100%;
  }

  .contact_bg ul li span {
    padding: 0px 20px 0px 40px;
    line-height: 160%;
  }

  .contact_bg ul li span p {
    padding: 0px 20px;
  }

  .contact_enquiry {
    padding-left: 0px;
  }

  .board_hr {
    width: 300px;
  }

  .board_head_card {
    width: 270px;
    height: 200px;
  }

  .board_img img {
    width: 135px;
    height: 150px;
  }

  .upcoming_events_card {
    min-height: 430px !important;
    max-height: 100px !important;
    overflow: hidden;
  }
}