.admin__pannel .sidebar {
    background: #dfebf9 !important;
}

.admin__pannel .navbar .navbar-brand-wrapper .brand-logo-mini {
    padding-left: 23px;
}

.admin__pannel .sidebar .nav.sub-menu {
    background: #7d7df6d4!important;
    padding: 0.25rem 0.5rem;
}

.admin__pannel .sidebar .nav.sub-menu .nav-item::before {
    content: none;
}
.admin__pannel .sidebar .sub__menu .nav-link:hover{
    background: #4B49AC !important;
}

.admin__pannel .menu__title {
    margin-left: 1rem !important;
}

.admin__pannel .nav__item__title {
    padding: 0.5rem 0.5rem;
    color: #0d1014;
    font-size: 0.875rem;
    margin-bottom: 0px !important;
}
.admin__pannel .sidebar .nav.sub-menu .nav-item .nav-link {
    padding: 0.7rem 0.5rem;
}

/* submenu start */
.admin__pannel .css-1pnmrwp-MuiTypography-root {
    font-size: 0.81rem;
    line-height: 1;
    color: #d9d9d9;
}
.admin__pannel .css-1m4ljmh-MuiTypography-root {
    font-size: 0.81rem;
    line-height: 1;
    white-space: nowrap;
    letter-spacing: 0.00938em;
    color: #d9d9d9;
}
.admin__pannel .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
    margin: 5px 0;
}
.admin__pannel .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
    min-height: 30px;
    padding: 0px 0px 0px 7px;
    background: #4B49AC;
    color: #fff;
    width: 100%;
}
.admin__pannel .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
    margin: 10px 0;
}
.admin__pannel .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    min-height: 0px;
}
.admin__pannel .css-1elwnq4-MuiPaper-root-MuiAccordion-root { 
    background: #8D8FF6;
    margin-bottom: 5px;
}
.admin__pannel .css-1elwnq4-MuiPaper-root-MuiAccordion-root:first-of-type {
    margin-bottom: 5px;
}
.admin__pannel .css-pwcg7p-MuiCollapse-root {
    background: #8D8FF6;
}
.admin__pannel .css-15v22id-MuiAccordionDetails-root {
    padding: 8px 4px 16px;
}

/* submenu end  */



@media (min-width: 992px){
.sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title {
    margin-left: 0rem !important;
}
}